import itForcesCover from '../../assets/img/web/project_cover_itforces.png';
import itForcesCoverTablet from '../../assets/img/tablet/project_cover_itforces.png';
import itForcesCoverMobile from '../../assets/img/mobile/project_cover_itforces.png';
import Button from '../UI/Button/Button';
import ProjectCard from './ProjectCard';
import './our-projects.scss';
import { useTranslation } from 'react-i18next';

export const OurProjects = () => {
    const { t } = useTranslation();
    const projects = [
        {
            id: 0,
            title: t('musu-projektai-it-forces-title'),
            description: t('musu-projektai-it-forces-description'),
            image: itForcesCover,
            imageTablet: itForcesCoverTablet,
            imageMobile: itForcesCoverMobile,
            altText: 'IT Forces project image',
        },
        {
            id: 1,
            title: t('musu-projektai-jau-greitai'),
            description: '',
            image: '',
        },
        {
            id: 2,
            title: '',
            description: '',
            image: '',
        },
        {
            id: 3,
            title: '',
            description: '',
            image: '',
        },
    ];

    return (
        <section className="our-projects">
            <div className="our-projects__inner-container">
                <h3 className="our-projects__title">
                    {t('musu-projektai-section-title_1')}{' '}
                    <span>{t('musu-projektai-section-title_2')}</span>
                </h3>

                <div className="our-projects__cards-container">
                    {projects.map((project) => (
                        <ProjectCard
                            key={project.id}
                            title={project.title}
                            description={project.description}
                            altText={project.altText}
                            image={project.image}
                            imageTablet={project.imageTablet}
                            imageMobile={project.imageMobile}
                        />
                    ))}
                </div>
                <div className="our-projects__button-container">
                    <Button
                        buttonText={t('daugiau-projektu-btn')}
                        isSecondaryButton
                    />
                </div>
            </div>
        </section>
    );
};
